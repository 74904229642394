import { createContext } from 'react';

export type RateModalContextType = {
  open: boolean;
  openRateModal: () => void;
  closeRateModal: () => void;
};

const RateModalContext = createContext<RateModalContextType>({
  open: false,
  openRateModal: () => null,
  closeRateModal: () => null,
});

export default RateModalContext;
