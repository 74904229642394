const suffix = '/page';

export const LANDING = '/';

export const EXCURSION = suffix + '/excursion';
export const COMMITTEE = suffix + '/committee';
export const LEGISLATIVE = suffix + '/legislative';
export const MEMBERS = suffix + '/members';
export const PETITION = suffix + '/petition';
export const MOBILE_APP = suffix + '/mobile-app';
