import { createContext } from 'react';

export type DataContextType = {
  members: any;
  membersLoading: boolean;
  updateMembers: () => Promise<void>;
  legislative: any;
  legislativeLoading: boolean;
  updateLegislative: () => Promise<void>;
};

const DataContext = createContext<DataContextType>({
  members: [],
  membersLoading: false,
  legislative: [],
  legislativeLoading: false,
  updateMembers: async () => {},
  updateLegislative: async () => {},
});

export default DataContext;
