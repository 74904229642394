import React from 'react';
import styled from 'styled-components/macro';
import MainComponent from 'components/common/MainComponent';
import { font } from 'theme/colors';
import { ReactComponent as TriangleDown } from 'assets/icons/triangle-down.svg';
import { ReactComponent as Parliament } from 'assets/icons/parliament.svg';
import { ReactComponent as Mission } from 'assets/icons/mission.svg';
import Button from '../common/Button';
import { useHistory } from 'react-router-dom';
import { EXCURSION } from '../../utils/routes';

const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1091px;
  margin-left: auto;
  margin-right: auto;
`;

const $Description = styled.div`
  text-align: center;
  font-size: 26px;
  color: #000000;
  font-family: ${font.secondary};
  margin-top: 110px;
  line-height: 32px;
`;

const $Options = styled.div`
  display: flex;
  align-items: center;
  margin-top: 42px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    line-height: 27px;
    color: rgba(29, 110, 187, 1);
    font-family: ${font.secondary};
    text-align: center;
    width: 372px;
    &:first-child {
      margin-right: 150px;
    }
    & svg {
      margin-bottom: 32px;
    }
  }
`;

const $Actions = styled.div`
  margin-top: 36px;
  text-align: center;
  & svg {
    margin-bottom: 36px;
  }
`;

const Excursion = () => {
  const history = useHistory();
  return (
    <MainComponent title="ექსკურსია პარლამენტში">
      <$Wrapper>
        <$Description style={{ marginBottom: 36 }}>
          თქვენ გაქვთ შესაძლებლობა, ეწვიოთ საქართველოს პარლამენტს
        </$Description>
        <$Options>
          <div>
            <Parliament />
            დაათვალიეროთ საქართველოს პარლამენტის სასახლე
          </div>
          <div>
            <Mission />
            გაეცანით პარლამენტის საქმიანობას
          </div>
        </$Options>
        <$Description style={{ marginTop: 50 }}>
          {/*თქვენ ასევე შეძლებთ გაიგოთ მეტი საქართველოში პარლამენტარიზმის*/}
          {/*საფუძვლებსა და ისტორიაზე.*/}
        </$Description>
        <$Actions>
          <TriangleDown />
          <Button onClick={() => history.push(EXCURSION + '/view')}>
            გაიგე მეტი
          </Button>
        </$Actions>
      </$Wrapper>
    </MainComponent>
  );
};

export default Excursion;
