(Date as any).prototype.addDays = function(days: any) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const getFirstAndLastDates = (year: any, month: any) => {

  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);

  return [firstDay, lastDay];
};

export const getFormatedDate = (date: any) => {
  return ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
};

export const getDates = (startDate: any, stopDate: any) => {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    const tempDate = getFormatedDate(new Date(currentDate));
    dateArray.push(tempDate);
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
};

export const reverseDate = (date: any) => {
  return date.split(' ')[0].split('-').reverse().join('-');
};
