import React from 'react';
import styled from 'styled-components/macro';
import MainComponent from 'components/common/MainComponent';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { font } from '../../theme/colors';

const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1120px;
  height: calc(100vh - 250px);
  margin-left: auto;
  margin-right: auto;
`;

const $Title = styled.div`
  font-family: ${font.secondary};
  font-weight: 400;
  font-size: 23px;
  text-align: center;
  line-height: 26px;
  margin-bottom: 24px;
`;

const $Description = styled.div`
  font-family: ${font.secondary};
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  line-height: 19px;
`;

const $ContactBox = styled.div`
  & > .contact {
    display: flex;
    flex-direction: row;
    font-family: ${font.secondary};
    font-weight: 400;
    font-size: 23px;
    & > div {
      display: flex;
      align-items: center;
      margin-right: 26px;
      & svg {
        margin-right: 10px;
        margin-top: -2px;
      }
    }
  }
`;

const ExcursionView = () => {

  return (
    <MainComponent title="ექსკურსია">
      <$Wrapper>
        <div>
          <$Title>
            ექსკურსიაზე გაეცნობით პარლამენტის ისტორიას და დაათვალიერებთ:
          </$Title>
          <$Description>
            • პარლამენტის სასახლის შენობას<br />
            • პარლამენტის პლენარულ სხდომათა დარბაზს<br />
            • კინოდარბაზს<br />
            • პირველი რესპუბლიკის, დავით აღმაშენებლის, შოთა რუსთაველისა და თამარ მეფის სახელობის დარბაზებს<br />
            • საქართველოს პარლამენტარიზმის მუზეუმს<br />
            • ხარების სახელობის სამლოცველოს პარლამენტის ეზოში<br />
            <br />
            <br />
            საექსკურსიო ტურები ეწყობა ყოველდღე, ორშაბათიდან პარასკევის ჩათვლით 12:00, 15:00 და 17:00 საათზე<br />
            ტურის ხანგრძლივობა: 60-70 წუთი
            <br />
            <br />
            <br />
          </$Description>
          <$Title>პარლამენტში ექსკურსია უფასოა!</$Title>
          <$Description>
            <br />
            დამატებითი ინფორმაციისთვის და ტურის დასაჯავშნად დაგვიკავშირდით:
          </$Description>
        </div>
        <$ContactBox>
          <div className="contact">
            <div>
              <Email />
              tours@parliament.ge
            </div>
            <div>
              <Phone />
              (+995 32) 228 16 93&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(+995 32) 228 16
              90
            </div>
          </div>
        </$ContactBox>
      </$Wrapper>
    </MainComponent>
  );
};

export default ExcursionView;
