export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  yellow: '#E8D880',
  lightBlue: '#3F8DD1',
  blue: '#092878',
};

export const font = {
  primary: 'Datebashvili',
  secondary: 'BPG ExtraSquare Mtavruli',
};
