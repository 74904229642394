import React from 'react';
import styled from 'styled-components/macro';
import { colors, font } from 'theme/colors';

const $ButtonRoot = styled.a<{ large?: boolean; }>`
  cursor: pointer;
  width: 458px;
  height: 118px;
  background: radial-gradient(155.39% 155.39% at 40.78% -55.39%, #3F8DD1 0%, #092878 100%);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: ${colors.white};
  font-family: ${font.primary};
  box-shadow: 0 4px 0 0 rgba(63, 141, 209, 1);
`;

type PropTypes = {
  [key: string]: any;
};

const Button: React.FC<PropTypes> = ({ children, ...props }) => {

  return (
    <$ButtonRoot {...props}>
      {children}
    </$ButtonRoot>
  );
};

export default Button;
