import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import MainComponent from 'components/common/MainComponent';
import { colors, font } from 'theme/colors';
import apiConfig, { fetchApi } from '../../utils/api';
import Loader from '../common/Loader';
import ModalContext from '../context/ModalContext';
import { ReactComponent as Loop } from 'assets/icons/loop.svg';
import DataContext from '../context/DataContext';

const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 17px;
  margin-left: auto;
  margin-right: auto;
`;

const $SearchBox = styled.div`
  width: calc(100% - 72px);
  margin-left: 72px;
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  padding-right: 23px;
  height: 75px;
  border: 1px solid #cdcdcd;
  background-color: #ffffff;
  align-items: center;
`;

const $LoopIcon = styled(Loop)`
  cursor: pointer;
  margin-left: 31px;
  margin-right: 30px;
  width: 33px;
  height: 33px;
`;

const $SearchInputComponent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const $SearchInput = styled.input`
  font-family: ${font.secondary};
  font-size: 21px;
  line-height: 25px;
  padding-left: 22px;
  background-color: #e7e7e7;
  border-radius: 12px;
  color: rgba(0, 0, 0, 0.44);
  width: 100%;
  outline: 0;
  border: 0;
  height: 41px;
  padding-top: 5px;
`;

const $List = styled.div`
  margin-top: 37px;
  width: 100%;
  max-height: calc(100vh - 260px);
  overflow: auto;
  padding-right: 36px;
  position: relative;
  min-height: 200px;
  &::-webkit-scrollbar {
    width: 23px;
    height: 23px;
    background: rgba(196, 196, 196, 0.37);
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background: #1d6ebb;
    border-radius: 100px;
  }
`;

const $Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 65px;
`;

const $Box = styled.div`
  display: flex;
  width: 545px;
  height: 226px;
  background-color: #ffffff;
  border-radius: 14px;
  padding-top: 32px;
  padding-left: 38px;
  padding-bottom: 35px;
  padding-right: 38px;
  cursor: pointer;
  overflow: hidden;
  & > div {
    overflow: hidden;
  }
  & .img {
    width: 157px;
    height: 157px;
    margin-right: 30px;
    background-size: cover;
    background-position: top center;
    border-radius: 14px;
    flex-shrink: 0;
  }
  & .title {
    font-size: 20px;
    line-height: 24px;
    color: #1d6ebb;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-family: ${font.secondary};
  }
  & .fraction-label {
    font-size: 17px;
    line-height: 20px;
    color: #9e9e9e;
    margin-top: 10px;
    font-family: ${font.secondary};
  }
  & .fraction {
    font-size: 18px;
    line-height: 22px;
    color: #2d2d2d;
    margin-top: 6px;
    font-family: ${font.secondary};
  }
`;

const $ModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${font.secondary};
  font-size: 19px;
  line-height: 23.9px;
  color: #3d3d3d;
  margin-top: 24px;
  margin-bottom: 24px;
  & .title {
    display: block;
    font-size: 24px;
    line-height: 29px;
    position: relative;
    color: #6a6a6a;
    padding-bottom: 6px;
    margin-bottom: 12px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #1d6ebb;
      height: 1px;
      width: 82px;
    }
  }
`;

const $MemberAvatar = styled.div`
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: top center;
  border-radius: 18px;
  flex-shrink: 0;
`;

const $MemberTitle = styled.div`
  font-size: 26px;
  margin-top: 35px;
  color: #6a6a6a;
  line-height: 31px;
  font-family: ${font.secondary};
  text-align: center;
`;

const $MemberEmail = styled.div`
  font-size: 26px;
  margin-top: 10px;
  color: #1d6ebb;
  line-height: 31px;
  font-family: ${font.secondary};
  text-align: center;
`;

const $MemberInfoBox = styled.div`
  font-size: 20px;
  margin-top: 30px;
  color: #5a5a5a;
  font-family: ${font.secondary};
  display: flex;
  justify-content: space-between;
  width: 660px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    & .title {
      color: #1d6ebb;
      margin-bottom: 10px;
    }
  }
`;

const Members = () => {
  const { members, updateMembers, membersLoading } = useContext(DataContext);
  const { openLiveModal } = useContext(ModalContext);
  const [input, setInput] = useState('');

  useEffect(() => {
    updateMembers();
  }, []);

  const data: any = useMemo(() => {
    if (!!input) {
      return members.filter((member: any) =>
        `${member.firstName} ${member.familyName} ${member.firstNameEn} ${member.familyNameEn}`.includes(
          input,
        ),
      );
    }
    return members;
  }, [members, input]);

  const list: any = useMemo(() => {
    const result: any = [];
    let count = 1;
    if (data?.length > 0) {
      let subArr: any = [];
      data.forEach((r: any) => {
        subArr.push(r);
        if (count % 3 === 0) {
          result.push([...subArr]);
          subArr = [];
        }
        count++;
      });
      if (subArr.length > 0) {
        result.push([...subArr]);
      }
    }
    return result;
  }, [data]);

  const clickHandle = async (r: any, factionName: any) => {
    try {
      openLiveModal({
        content: <Loader type="Oval" color={colors.blue} />,
      });
      const [infoData, educationData, appointmentData] = await Promise.all([
        fetchApi(`${apiConfig.colleague.getColleagues}${r.id}`),
        fetchApi(`${apiConfig.colleague.getColleagues}${r.id}/education`),
        fetchApi(`${apiConfig.colleague.getColleagues}${r.id}/appointment`),
      ]);
      const [info] = infoData;
      const education = educationData?.list;
      const appointment = appointmentData?.[0].appointments?.[0];
      openLiveModal({
        content: (
          <>
            <$MemberAvatar style={{ backgroundImage: `url(${r.image})` }} />
            <$MemberTitle>
              {info.firstName} {info.familyName}
            </$MemberTitle>
            {info.contactInfo?.publicEmail1 && (
              <$MemberEmail>{info.contactInfo?.publicEmail1}</$MemberEmail>
            )}
            <$MemberInfoBox>
              {info.dob && (
                <div>
                  <div className="title">დაბადების თარიღი</div>
                  <div>{info.dob}</div>
                </div>
              )}
              {info.contactInfo?.roomT && (
                <div>
                  <div className="title">ოთახი</div>
                  <div>{info.contactInfo?.roomT}</div>
                </div>
              )}
              {info.contactInfo?.publicPhoneT1 && (
                <div>
                  <div className="title">ტელეფონის ნომერი</div>
                  <div>{info.contactInfo?.publicPhoneT1}</div>
                </div>
              )}
            </$MemberInfoBox>
            {!!factionName && (
              <$ModalInfo>
                <div className="title">ფრაქცია</div>
                  <div style={{ paddingBottom: 6 }}>
                    {factionName}
                  </div>
              </$ModalInfo>
            )}
            {education && education.length > 0 && (
              <$ModalInfo>
                <div className="title">განათლება</div>
                {education.map((e: any) => (
                  <div style={{ paddingBottom: 12 }} key={e.id}>
                    <span className="bullet">{'\u2022 '}</span>
                    <strong style={{ display: 'inline-block', paddingBottom: 6 }}>{e.graduate}</strong>
                    <br />
                    {e.speciality}, {e.qualification}
                  </div>
                ))}
              </$ModalInfo>
            )}
            {appointment && (
              <$ModalInfo>
                <div className="title">
                  {appointment.node} ({appointment.from})
                </div>
                <div
                  style={{
                    color: '#6A6A6A',
                    fontSize: 22,
                    marginTop: 6,
                    marginBottom: 6,
                    lineHeight: '30px',
                  }}
                >
                  <span className="bullet">{'\u2022 '}</span>{' '}
                  {appointment.position}
                </div>
                <div
                  style={{
                    color: '#6A6A6A',
                    fontSize: 22,
                    marginTop: 6,
                    marginBottom: 6,
                    lineHeight: '30px',
                  }}
                >
                  <span className="bullet">{'\u2022 '}</span> საარჩევნო ბლოკი:{' '}
                  {appointment.electionBlock}
                </div>
                <div
                  style={{
                    color: '#6A6A6A',
                    fontSize: 22,
                    marginTop: 6,
                    marginBottom: 6,
                    lineHeight: '30px',
                  }}
                >
                  <span className="bullet">{'\u2022 '}</span> საარჩევნო ფორმა:{' '}
                  {appointment.electionType}
                </div>
              </$ModalInfo>
            )}
          </>
        ),
      });
    } catch (e) {
      console.log(e, 'e');
    }
  };

  return (
    <MainComponent
      title="დეპუტატები"
      style={{ background: '#E5E5E5' }}
    >
      <$Wrapper>
        <$SearchBox>
          <$LoopIcon />
          <$SearchInputComponent>
            <$SearchInput
              placeholder="საძიებო სიტყვა"
              onKeyPress={(e: any) => setInput(e.target?.value)}
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </$SearchInputComponent>
        </$SearchBox>
        <$List>
          {membersLoading && list.length <= 0 && <Loader />}
          {list.map((arr: any, i: number) => (
            <$Row key={`row-member-${i}`}>
              {arr.map((r: any) => (
                <$Box key={r.id} onClick={() => clickHandle(r, r.factionName || null)}>
                  <div
                    style={{ backgroundImage: `url(${r.image})` }}
                    className="img"
                  />
                  <div>
                    <div className="title">
                      {r.firstName} {r.familyName}
                    </div>
                  </div>
                </$Box>
              ))}
            </$Row>
          ))}
        </$List>
      </$Wrapper>
    </MainComponent>
  );
};

export default Members;
