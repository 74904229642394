import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';
import MainComponent from 'components/common/MainComponent';
import { colors, font } from 'theme/colors';
import Loader from '../common/Loader';
import { MONTHS } from '../landing/CalendarConstants';
import ModalContext from '../context/ModalContext';
import { truncateString } from '../../utils/helpers';

const $Overflow = styled.div`
  width: 100%;
  margin-top: 10px;
  max-height: calc(100vh - 140px);
  overflow: auto;
  //&::-webkit-scrollbar {
  //  width: 23px;
  //  height: 23px;
  //  background: rgba(196, 196, 196, 0.37);
  //  border-radius: 100px;
  //}
  //&::-webkit-scrollbar-thumb {
  //  background: #1d6ebb;
  //  border-radius: 100px;
  //}
`;

const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 70px;
`;

const $Description = styled.div`
  text-align: center;
  font-size: 21px;
  color: #000000;
  font-family: ${font.secondary};
  line-height: 25.75px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  a {
    color: #1d6ebb;
  }
  &.helper {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.3s, opacity 0.3s;
    &.active {
      height: 105px;
      opacity: 1;
    }
  }
`;

const $BoldTitle = styled.div`
  text-align: center;
  font-size: 21px;
  color: #103456;
  font-weight: 600;
  font-family: ${font.primary};
  line-height: 24px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
`;

const $SubDescription = styled.div`
  text-align: center;
  font-size: 25px;
  color: #014587;
  font-family: ${font.secondary};
  margin-top: 41px;
  margin-bottom: 80px;
  line-height: 30px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
`;
//
// const $Divider = styled.div`
//   width: 123px;
//   height: 5px;
//   border-radius: 100px;
//   margin-top: 17px;
//   margin-bottom: 19px;
//   margin-left: auto;
//   margin-right: auto;
//   background: ${colors.lightBlue};
// `;

const $BlueButton = styled.div`
  width: 460px;
  height: 131px;
  border-radius: 15px;
  font-size: 20px;
  line-height: 28px;
  box-shadow: 0 4px 0 0 #1e5080;
  background: #1d6ebb;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  padding: 20px;
  padding-top: 22px;
  padding-bottom: 18px;
  margin-bottom: 50px;
  color: #ffffff;
  font-family: ${font.primary};
  cursor: pointer;
`;

const $PetitionList = styled.div`
  position: relative;
  width: 100%;
  padding-top: 58px;
  padding-bottom: 50px;
  padding-left: 58px;
  overflow: auto;
  display: flex;
  margin-bottom: 10px;
  min-height: 260px;
  &::-webkit-scrollbar {
    width: 29px;
    height: 29px;
    background: rgba(196, 196, 196, 0.5);
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.yellow};
  }
`;

const $ListItem = styled.div`
  min-width: 682px;
  height: 158px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  background-color: #1d6ebb;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-right: 25px;
  cursor: pointer;
  & .title {
    display: flex;
    font-family: ${font.secondary};
    font-size: 23px;
    line-height: 28px;
    color: #ffffff;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  & .info {
    width: 450px;
    font-size: 25px;
    line-height: 30px;
    color: #ffffff;
    font-family: ${font.secondary};
    display: flex;
    flex-direction: row;
    align-items: baseline;
    & .head {
      font-family: ${font.secondary};
      opacity: 0.5;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      padding-bottom: 15px;
      margin-right: 30px;
      white-space: nowrap;
      & + div {
        white-space: nowrap;
      }
    }
  }
`;

const $ModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${font.secondary};
  font-size: 19px;
  line-height: 23.9px;
  color: #3d3d3d;
  margin-top: 24px;
  margin-bottom: 24px;
  & .title {
    display: block;
    font-size: 24px;
    line-height: 29px;
    position: relative;
    color: #6a6a6a;
    padding-bottom: 6px;
    margin-bottom: 12px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #1d6ebb;
      height: 1px;
      width: 82px;
    }
  }
`;

const formatDate = (date: string) => {
  const d = new Date(date);
  return `${d.getDate()}-${MONTHS[d.getMonth()]} ${d.getFullYear()}`;
};

const Petition = () => {
  const [petitions, setPetitions] = useState([]);
  const { openLiveModal } = useContext(ModalContext);
  const [loader, setLoader] = useState(false);
  const [helper, setHelper] = useState(false);

  useEffect(() => {
    (async () => {
      setLoader(true);
      try {
        // BEFORE BUILD
        // const result = await fetch('/api/search?category=PETITION&page=0&size=9');
        const result = await fetch(
          'https://esignature.parliament.ge/api/search?category=PETITION&page=0&size=9',
        );
        const data = await result.json();
        setPetitions(data.content);
      } catch (e) {
        console.log(e, 'e');
      } finally {
        setLoader(false);
      }
    })();
  }, []);

  const clickHandle = async (record: any) => {
    openLiveModal({
      content: <Loader type="Oval" color={colors.blue} />,
    });
    try {
      // BEFORE BUILD
      // const result = await fetch('/api/petition/' + record.id);
      const result = await fetch(
        'https://esignature.parliament.ge/api/petition/' + record.id,
      );
      const data = await result.json();
      openLiveModal({
        content: (
            <>
              <$ModalInfo>
                <div className="title">დასახელება</div>
                <div>{data.title}</div>
              </$ModalInfo>
              <$ModalInfo>
                <div className="title">რეგისტრაციის ნომერი</div>
                <div>{data.registrationNumber}</div>
              </$ModalInfo>
              <$ModalInfo>
                <div className="title">რეგისტრაცის თარიღი</div>
                <div>{formatDate(data.registrationTime)}</div>
              </$ModalInfo>
              {data.authors?.length > 0 && (
                  <$ModalInfo>
                    <div className="title">პეტიციის ავტორი</div>
                    <div>{data.authors[0].firstName} {data.authors[0].lastName}</div>
                  </$ModalInfo>
              )}
              {data.status && (
                  <$ModalInfo>
                    <div className="title">სტატუსი</div>
                    <div>{data.status.value}</div>
                  </$ModalInfo>
              )}
              {typeof data.supportersBefore === 'number' && (
                  <$ModalInfo>
                    <div className="title">მხარდამჭერთა რაოდენობა წარდგენისას</div>
                    <div>{data.supportersBefore}</div>
                  </$ModalInfo>
              )}
              {typeof data.supporters === 'number' && (
                  <$ModalInfo>
                    <div className="title">ელექტრონული სახით მხარდამჭერთა რაოდენობა</div>
                    <div>{data.supporters}</div>
                  </$ModalInfo>
              )}
              {(!!data.supporters || !!data.supportersBefore) && (
                  <$ModalInfo>
                    <div className="title">მხარდამჭერთა სრული რაოდენობა</div>
                    <div>{(data.supporters || 0) + (data.supportersBefore + 0)}</div>
                  </$ModalInfo>
              )}
            </>
        ),
      });
    } catch (e) {

    }
  };

  return (
    <MainComponent title="პეტიცია">
      <$Overflow>
        <$Wrapper>
          <$Description>
            თუ გაწუხებთ სახელმწიფოებრივი ან/და საზოგადოებრივი პრობლემიდან
            გამომდინარე საკითხები
          </$Description>
          <$Description
            style={{
              marginTop: 18,
              marginBottom: 18,
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <span style={{ color: '#1D6EBB', paddingRight: 8, fontSize: 26, paddingTop: 2 }}>•</span>
            შეგიძლიათ დაარეგისტრიროთ პეტიცია
            <Pencil style={{ position: 'absolute', right: -72, top: -8 }} />
          </$Description>
          <$Description style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: '#1D6EBB', paddingRight: 8, fontSize: 26, paddingTop: 2 }}>•</span>1 თვის
            ვადაში შეაგროვოთ 300 ხელმოწერა
          </$Description>
          <$SubDescription>
            და მას საქართველოს პარლამენტი განიხილავს.
          </$SubDescription>
          <$BlueButton onClick={() => setHelper((prev) => !prev)}>
            როგორ წარვადგინოთ პეტიცია?
          </$BlueButton>
          <$Description className={`helper ${helper ? 'active' : ''}`}>
            პეტიციის ინიცირება შეგიძლიათ წერილობითი ან ელექტრონული
            ფორმით.წერილობით პეტიციის წარდგენის შემთხვევაში, პეტიცია და
            ხელმომწერთა სია დატოვეთ პარლამენტის მოქალაქეთა
            მისაღებში.ელექტრონულად პეტიციის წარდგენის შემთხვევაში ეწვიეთ
            ვებგვერდს:
            <br />
            {/* eslint-disable-next-line */}
            <a>parliament.ge</a>
          </$Description>
          <$BoldTitle>გაეცანი მიმდინარე პეტიციებს</$BoldTitle>
          <$PetitionList>
            {loader && <Loader type="Oval" color={colors.blue} />}
            {petitions.map((petition: any) => (
              <$ListItem key={petition.id} onClick={() => clickHandle(petition)}>
                <div className="title">
                  {petition.title && truncateString(petition.title, 132)}
                </div>
              </$ListItem>
            ))}
          </$PetitionList>
        </$Wrapper>
      </$Overflow>
    </MainComponent>
  );
};

export default Petition;
