import React from 'react';
import ReactDOM from 'react-dom';
import { Normalize } from 'styled-normalize';
import GlobalStyles from './theme/globalStyles';
import './theme/fonts.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Normalize />
    <GlobalStyles />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
