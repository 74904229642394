import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Landing from './components/landing/Landing';
import Excursion from './components/pages/Excursion';
import Legislative from './components/pages/Legislative';
import Petition from './components/pages/Petition';
import {
  COMMITTEE,
  EXCURSION,
  LANDING,
  LEGISLATIVE,
  MEMBERS,
  MOBILE_APP,
  PETITION,
} from './utils/routes';
import './styles.css';
import ModalContext, {
  ModalPropsType,
} from './components/context/ModalContext';
import Modal from './components/common/Modal';
import Members from './components/pages/Members';
import Committee from './components/pages/Committee';
import CommitteeView from './components/pages/CommitteeView';
import ExcursionView from './components/pages/ExcursionView';
import Mobile from './components/pages/Mobile';
import DataContext from 'components/context/DataContext';
import apiConfig, { fetchApi } from './utils/api';
import { queryString } from './utils/helpers';
import RateModal from './components/common/RateModal';
import RateModalContext from 'components/context/RateModalContext';

(window as any).time = null;
window.onload = resetTimer;
document.onmousemove = resetTimer;
document.ontouchmove = resetTimer;
document.ontouchstart = resetTimer;
document.onkeydown = resetTimer;

function resetTimer() {
  clearTimeout((window as any).time);
  (window as any).time = setTimeout(() => {
    if (window.location.href.includes('/page')) {
      // window.location.assign('/');
      // BEFORE BUILD
      window.location.assign('/test-parliament');
    } else {
      resetTimer();
    }
  }, 180000);
}

const App = () => {
  const [rateModal, setRateModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [membersLoading, setMembersLoading] = useState(false);
  const updateMembers = useCallback(async () => {
    if (!membersLoading) {
      try {
        setMembersLoading(true);
        const params = {
          start: 0,
          limit: 150,
          colleagueStatusId: 1,
          fillFaction: true,
          fillVotinngCount: false,
          positionId: 130,
        };
        const result = await fetchApi(
          `${apiConfig.colleague.getColleagues}?${queryString(params)}`,
        );
        if (result.list) {
          setMembers(
            result.list.map((r: any) => ({
              id: r.id,
              factionName: r.factionName,
              firstName: r.firstName,
              firstNameEn: r.firstNameEn,
              familyName: r.familyName,
              familyNameEn: r.familyNameEn,
              image: `https://info.parliament.ge/hr/image/1/colleague/${r.id}`,
            })),
          );
        }
      } catch (e) {}
      setMembersLoading(false);
    }
  }, [membersLoading]);

  const [legislative, setLegislative] = useState([]);
  const [legislativeLoading, setLegislativeLoading] = useState(false);
  const updateLegislative = useCallback(async () => {
    if (!legislativeLoading) {
      try {
        setLegislativeLoading(true);
        const result = await fetchApi(
          `${apiConfig.law.bill}?billTypeId=24&start=0&limit=8`,
        );
        setLegislative(result.list);
      } catch (e) {}
      setLegislativeLoading(false);
    }
  }, [legislativeLoading]);

  const [modal, setModal] = useState<{
    show: boolean;
    props?: ModalPropsType | null;
  }>({
    show: false,
  });

  useEffect(() => {
    updateMembers();
    updateLegislative();
  }, []);

  const closeLiveModal = () => {
    setModal((oldState) => ({ ...oldState, show: false }));
  };

  const openLiveModal = (props: ModalPropsType) => {
    setModal({
      show: true,
      props: {
        handleClose: closeLiveModal,
        ...props,
      },
    });
  };

  const closeRateModal = () => {
    setRateModal(false);
  };

  const openRateModal = () => {
    setRateModal(true);
  };

  return (
    <DataContext.Provider
      value={{
        members,
        membersLoading,
        updateMembers,
        legislative,
        legislativeLoading,
        updateLegislative,
      }}
    >
      <ModalContext.Provider
        value={{
          open: modal.show,
          props: modal.props,
          closeLiveModal,
          openLiveModal,
        }}
      >
        <RateModalContext.Provider
          value={{
            open: rateModal,
            openRateModal,
            closeRateModal,
          }}
        >
          {/*BEFORE BUILD*/}
          <BrowserRouter basename="/test-parliament">
            {/*<BrowserRouter>*/}
            <Switch>
              <Route path={LANDING + ':launch?'} component={Landing} exact />
              <Route path={EXCURSION} component={Excursion} exact />
              <Route
                path={EXCURSION + '/view'}
                component={ExcursionView}
                exact
              />
              <Route path={MEMBERS} component={Members} exact />
              <Route path={LEGISLATIVE} component={Legislative} exact />
              <Route path={COMMITTEE} component={Committee} exact />
              <Route
                path={COMMITTEE + '/:id'}
                component={CommitteeView}
                exact
              />
              <Route path={MOBILE_APP} component={Mobile} exact />
              <Route path={PETITION} component={Petition} exact />
            </Switch>
            <RateModal />
          </BrowserRouter>
          <Modal />
        </RateModalContext.Provider>
      </ModalContext.Provider>
    </DataContext.Provider>
  );
};

export default App;
