import { createContext } from 'react';

export type ModalPropsType = {
  handleClose?: () => void;
  withClose?: boolean;
  content?: any;
  style?: any;
};

export type ModalContextType = {
  props?: ModalPropsType | null;
  open: boolean;
  openLiveModal: (data: ModalPropsType) => void;
  closeLiveModal: () => void;
};

const ModalContext = createContext<ModalContextType>({
  open: false,
  openLiveModal: () => null,
  closeLiveModal: () => null,
});

export default ModalContext;
