import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Transition } from 'react-transition-group';
import { ReactComponent as CloseIcon } from 'assets/icons/modal-close-icon.svg';
import ModalContext from '../context/ModalContext';
import { backdropTransitionStyles } from './Navigation';

const $Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1005;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
  overflow: auto;
`;

const $Content = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  padding: 52px;
  border-radius: 18px;
  margin: 0 auto;
  margin-bottom: 50px;
  flex-shrink: 0;
  opacity: 0;
  min-height: 300px;
  transform: scale(1.2);
  transition: opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const $Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(30, 80, 128, 0.83);
  opacity: 0;
  transition: opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const $CloseIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  right: 26px;
  top: 26px;
  z-index: 10;
`;

const modalTransitionStyles: any = {
  entering: { transform: 'scale(1.2)', opacity: 0 },
  entered: { transform: 'scale(1)', opacity: 1 },
  exiting: { transform: 'scale(1.2)', opacity: 0 },
  exited: { transform: 'scale(1.2)', opacity: 0 },
};

const Modal: React.FC = () => {
  const { open, closeLiveModal, props } = useContext(ModalContext);

  return (
    <Transition in={open} timeout={{ exit: 225 }} unmountOnExit>
      {(state) => (
        <$Modal>
          <$Backdrop
            onClick={closeLiveModal}
            style={{ ...backdropTransitionStyles[state] }}
          />
          <$Content
            style={{
              maxWidth: 992,
              ...props?.style,
              ...modalTransitionStyles[state],
            }}
          >
            <$CloseIcon onClick={closeLiveModal} />
            {props?.content}
          </$Content>
        </$Modal>
      )}
    </Transition>
  );
};

export default Modal;
