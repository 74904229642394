import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import MainComponent from 'components/common/MainComponent';
import { colors, font } from 'theme/colors';
import { truncateString } from '../../utils/helpers';
import Loader from '../common/Loader';
import ModalContext from '../context/ModalContext';
import DataContext from '../context/DataContext';

const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const $Description = styled.div`
  text-align: center;
  font-size: 27px;
  color: #000000;
  font-family: ${font.secondary};
  margin-top: 84px;
  line-height: 33.1px;
  max-width: 994px;
`;

const $SubDescription = styled.div`
  text-align: center;
  font-size: 24px;
  color: #000000;
  font-family: ${font.secondary};
  margin-bottom: 33px;
  line-height: 29px;
  max-width: 957px;
  a {
    color: #1d6ebb;
  }
`;

const $ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
`;

const $ListItem = styled.ul`
  font-family: ${font.secondary};
  font-size: 17px;
  line-height: 20px;
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    font-size: 17px;
    padding: 0;
    display: flex;
  }

  li > span {
    font-size: 80px;
    color: ${colors.lightBlue};
    margin-right: 10px;
    display: flex;
    padding-top: 6px;
  }
`;

const $Divider = styled.div`
  width: 440px;
  height: 4px;
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
  background: #002877;
  opacity: 0.5;
  margin-top: 24px;
  margin-bottom: 32px;
`;

const $Title = styled.div`
  width: 100%;
  padding-left: 56px;
  padding-right: 56px;
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  font-family: ${font.primary};
  color: #061b54;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & span {
    padding-right: 31px;
  }
  &::after {
    display: flex;
    content: '';
    right: 0;
    flex: 1;
    height: 9px;
    border-radius: 180px;
    background-color: #5a5a5a;
    opacity: 0.2;
  }
`;

const $LegislativeWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 65px;
  padding-left: 58px;
  overflow: auto;
  display: flex;
  margin-bottom: 30px;
  min-height: 300px;
  &::-webkit-scrollbar {
    width: 29px;
    height: 29px;
    background: rgba(196, 196, 196, 0.5);
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.yellow};
  }
  div.item {
    cursor: pointer;
    display: flex;
    width: 383px;
    height: 202px;
    background: radial-gradient(
      93.56% 173.65% at 33.81% 30.69%,
      #3778b7 0%,
      #1f456a 100%
    );
    box-shadow: 0 4px 0 0 rgba(30, 80, 128, 1);
    border-radius: 12px;
    padding: 24px;
    padding-top: 28px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    font-family: ${font.primary};
    flex-shrink: 0;
    margin-right: 56px;
  }
`;

const $ModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${font.secondary};
  font-size: 19px;
  line-height: 23.9px;
  color: #3d3d3d;
  margin-top: 24px;
  margin-bottom: 24px;
  & .title {
    display: block;
    font-size: 24px;
    line-height: 29px;
    position: relative;
    color: #6a6a6a;
    padding-bottom: 6px;
    margin-bottom: 12px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #1d6ebb;
      height: 1px;
      width: 82px;
    }
  }
`;

const Legislative = () => {
  const { legislativeLoading, legislative, updateLegislative } =
    useContext(DataContext);
  const { openLiveModal } = useContext(ModalContext);
  useEffect(() => {
    updateLegislative();
  }, []);

  const clickHandle = (record: any) => {
    openLiveModal({
      content: (
        <>
          <$ModalInfo>
            <div className="title">დასახელება</div>
            <div>{record.billName}</div>
          </$ModalInfo>
          <$ModalInfo>
            <div className="title">ინიციატორი</div>
            <div>{record.billPackage?.initiator}</div>
          </$ModalInfo>
          <$ModalInfo>
            <div className="title">თარიღი</div>
            <div>{record.billPackage?.registrationDate}</div>
          </$ModalInfo>
        </>
      ),
    });
  };

  return (
    <MainComponent title="საკანონმდებლო წინადადება">
      <$Wrapper>
        <$Description>
          საკანონმდებლო წინადადება არის საქართველოს პარლამენტისთვის წარდგენილი
          მიმართვა:
        </$Description>
        <$ListWrapper>
          <$ListItem>
            <li>
              <span>·</span>ახალი კანონის მიღებაზე
            </li>
          </$ListItem>
          <$ListItem>
            <li>
              <span>·</span>კანონში ცვლილებების შეტანაზე
            </li>
          </$ListItem>
          <$ListItem>
            <li>
              <span>·</span>კანონის ძალადაკარგულად გამოცხადებაზე
            </li>
          </$ListItem>
        </$ListWrapper>
        <$Divider />
        <$SubDescription>
          საკანონმდებლო წინადადება შეიძლება იყოს მზა კანონპროექტი ან კონკრეტული
          იდეა, რომელიც ასახავს ძირითად პრინციპებს ან კონკრეტულ წინადადებებს.
        </$SubDescription>
        <$SubDescription>
          ეწვიეთ ვებგვერდს და გაეცანი მიმდინარე საკანონმდებლო წინადადებებს:
          <br />
          {/* eslint-disable-next-line */}
          <a>parliament.ge</a>
        </$SubDescription>
        <$Title>
          <span>მიმდინარე საკანონმდებლო წინადადებები</span>
        </$Title>
        <$LegislativeWrapper>
          {legislativeLoading && legislative.length <= 0 && (
            <Loader type="Oval" color={colors.blue} />
          )}
          {legislative.map((bill: any) => (
            <div
              className="item"
              key={bill.id}
              onClick={() => clickHandle(bill)}
            >
              {bill.billName && truncateString(bill.billName, 165)}
            </div>
          ))}
        </$LegislativeWrapper>
      </$Wrapper>
    </MainComponent>
  );
};

export default Legislative;
