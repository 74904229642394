import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Transition } from 'react-transition-group';
import { ReactComponent as CloseIcon } from 'assets/icons/modal-close-icon.svg';
import { ReactComponent as SmileDown } from 'assets/icons/smile-down.svg';
import { ReactComponent as SmileUp } from 'assets/icons/smile-up.svg';
import { ReactComponent as SmileOk } from 'assets/icons/smile-ok.svg';
import { ReactComponent as SmileStar } from 'assets/icons/smile-star.svg';
import { backdropTransitionStyles } from './Navigation';
import RateModalContext from '../context/RateModalContext';
import { font } from '../../theme/colors';

const $Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1005;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 200px;
  overflow: auto;
`;

const $Content = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  padding: 52px;
  padding-bottom: 40px;
  border-radius: 18px;
  margin: 0 auto;
  margin-bottom: 50px;
  flex-shrink: 0;
  opacity: 0;
  min-height: 500px;
  max-height: 500px;
  transform: scale(1.2);
  transition: opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const $Title = styled.div`
  font-size: 21px;
  line-height: 26px;
  color: #092878;
  font-family: ${font.secondary};
  position: absolute;
  left: 42px;
  top: 32px;
`;

const $Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(30, 80, 128, 0.3);
  opacity: 0;
  transition: opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const $CloseIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  right: 26px;
  top: 26px;
  z-index: 10;
`;

const $Step1 = styled.div`
  max-width: 486px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  transform: scale(1.2);
  transition: opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const $Step2 = styled.div`
  max-width: 486px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 42px;
  transform: scale(1.2);
  transition: opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const $Description = styled.p`
  width: 100%;
  font-family: ${font.secondary};
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
  color: #000000;
  text-align: center;
  margin-bottom: 76px;
`;

const $Description2 = styled.p`
  width: 100%;
  font-family: ${font.secondary};
  font-size: 21px;
  line-height: 25px;
  font-weight: 400;
  text-align: center;
  color: #092878;
`;

const $RateSmiles = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

const $RateSmile = styled.div`
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c4c4c445;
  border-radius: 19px;
  border: 3px solid transparent;
  transition: all 0.5s;
  cursor: pointer;
  &.active {
    background-color: #ffffff;
    &.up {
      border: 3px solid #13d44a;
    }
    &.down {
      border: 3px solid #ff0000;
    }
    &.ok {
      border: 3px solid #ffd600;
    }
  }
`;

const modalTransitionStyles: any = {
  entering: { transform: 'scale(1.2)', opacity: 0 },
  entered: { transform: 'scale(1)', opacity: 1 },
  exiting: { transform: 'scale(1.2)', opacity: 0 },
  exited: { transform: 'scale(1.2)', opacity: 0 },
};

const $window: any = window;
$window.firstMainPageTime = null;
$window.lastMainPageTime = null;
$window.enableRate = false;
$window.blockRateModal = false;
let setMainPageTime: any;

const RateModal: React.FC = () => {
  const location = useLocation();
  const [submitted, setSubmitted] = useState('');
  const [step, setStep] = useState(1);
  const { open, closeRateModal, openRateModal } = useContext(RateModalContext);

  useEffect(() => {
    if (location.pathname === '/') {
      clearTimeout(setMainPageTime);
      if ($window.enableRate && !$window.blockRateModal) {
        $window.enableRate = false;
        setTimeout(() => {
          openRateModal();
        }, 600);
        $window.blockRateModal = true;
        setTimeout(() => {
          $window.blockRateModal = false;
        }, 300000);
      }
    } else {
      setMainPageTime = setTimeout(() => {
        $window.enableRate = true;
      }, 60000);
    }
  }, [location]);

  useEffect(() => {
    if (open) {
      setSubmitted('');
      setStep(1);
    }
  }, [open]);

  const handleRate = (rate: string) => {
    setSubmitted(rate);
    setTimeout(() => {
      setStep(2);
      setTimeout(() => {
        setStep(3);
      }, 250);
    }, 1500);
  };

  return (
    <Transition in={open} timeout={{ exit: 225 }} unmountOnExit>
      {(state) => (
        <$Modal>
          <$Backdrop
            onClick={closeRateModal}
            style={{ ...backdropTransitionStyles[state] }}
          />
          <$Content
            style={{
              maxWidth: 630,
              ...modalTransitionStyles[state],
            }}
          >
            <$Title>შეფასება</$Title>
            <$CloseIcon onClick={closeRateModal} />
            <Transition in={step === 1} timeout={{ exit: 300 }} unmountOnExit>
              {(state2) => (
                <$Step1 style={{ ...modalTransitionStyles[state2] }}>
                  <$Description>
                    გთხოვთ შეაფასოთ მოქალაქეთა მისაღების მომსახურების ხარისხი
                  </$Description>
                  <$RateSmiles>
                    <$RateSmile
                      onClick={() => handleRate('up')}
                      className={`up ${submitted === 'up' ? 'active' : ''}`}
                    >
                      <SmileUp />
                    </$RateSmile>
                    <$RateSmile
                      onClick={() => handleRate('ok')}
                      className={`ok ${submitted === 'ok' ? 'active' : ''}`}
                    >
                      <SmileOk />
                    </$RateSmile>
                    <$RateSmile
                      onClick={() => handleRate('down')}
                      className={`down ${submitted === 'down' ? 'active' : ''}`}
                    >
                      <SmileDown />
                    </$RateSmile>
                  </$RateSmiles>
                </$Step1>
              )}
            </Transition>

            <Transition in={step === 3} timeout={250} unmountOnExit>
              {(state2) => (
                <$Step2 style={{ ...modalTransitionStyles[state2] }}>
                  <div style={{ textAlign: 'center', marginBottom: 46 }}>
                    <SmileStar />
                  </div>
                  <$Description2>
                    დიდი მადლობა! თქვენი შეფასება ძალიან მნიშვნელოვანია
                    პარლამენტის სერვისების გასაუმჯობესებლად
                  </$Description2>
                </$Step2>
              )}
            </Transition>
          </$Content>
        </$Modal>
      )}
    </Transition>
  );
};

export default RateModal;
