import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'theme/colors';
import GlobalLoader from 'react-loader-spinner';

const $LoaderWrapper = styled.div`
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

type PropTypes = {
  [key: string]: any;
};

const Loader: React.FC<PropTypes> = ({ ...props }) => {
  return (
    <$LoaderWrapper>
      <GlobalLoader {...props} type="Oval" color={colors.blue} />
    </$LoaderWrapper>
  );
};

export default Loader;
