const apiConfig: any = {
  // url: '',
  // BEFORE BUILD
  url: 'https://info.parliament.ge',
  content: {},
  limit: 10,
  law: {},
  colleague: {},
  agendaTypeNames: {
    ToVoting: 'კენჭსაყრელი საკითხები',
    ToReview: 'განსახილველი საკითხები',
    NewInitiatives: 'ახალი საკანონმდებლო ინიციატივები',
    Organizational: 'საორგანიზაციო საკითხები',
  },
  calendar: {},
  options: {
  },
};

/* LAW CONTROLLER */
apiConfig.law.path = apiConfig.url + '/law/1';
apiConfig.law.like = apiConfig.url + '/law/1/bill/{id}/like';
apiConfig.colleague.path = apiConfig.url + '/hr/colleague/1';

// actions
apiConfig.law.agenda = apiConfig.law.path + '/agenda';
apiConfig.law.agendaView = apiConfig.law.agenda + '/{id}';
apiConfig.law.agendaCountType = apiConfig.law.agenda + '/{id}/billReviewCountByType';
apiConfig.law.agendaReviewList = apiConfig.law.agendaView + '/billReview';

apiConfig.law.author = apiConfig.law.path + '/author';

apiConfig.law.bill = apiConfig.law.path + '/bill';
apiConfig.law.billView = apiConfig.law.bill + '/{id}';
apiConfig.law.billReviews = apiConfig.law.bill + '/{id}/billReview';

apiConfig.law.comments = apiConfig.law.bill + '/{id}/billNote';
apiConfig.law.sendCode = apiConfig.law.comments;
apiConfig.law.submitComment = apiConfig.law.comments + '/{commentID}';

apiConfig.law.registrationNumber = apiConfig.law.path + '/registrationNumber';
apiConfig.law.committeeName = apiConfig.law.path + '/committeeName';
apiConfig.law.committeeNameCurrent = 'https://info.parliament.ge/hr/structure/1/node/query?expressionId=21&mod=current&';
apiConfig.law.documentNumber = apiConfig.law.path + '/documentNumber';
apiConfig.law.bureauNumber = apiConfig.law.path + '/bureauNumber';
apiConfig.law.billType = apiConfig.url + '/law/lib/1/billType';

apiConfig.law.voteSession = apiConfig.law.path + '/voteSession';
apiConfig.law.voting = apiConfig.law.path + '/voting';

apiConfig.law.billReview = apiConfig.law.bill + 'Review/{id}';
apiConfig.law.billReviewMap = apiConfig.law.billReview + '/map';
apiConfig.law.billCommittee = apiConfig.law.billReview + '/billCommittee';
apiConfig.law.billReviewContent = apiConfig.law.billReview + '/billReviewContent';
apiConfig.law.billPackageContent = apiConfig.law.billReview + '/billPackageContent';
apiConfig.law.billReviewSpeaker = apiConfig.law.billReview + '/billReviewSpeaker';
apiConfig.law.supervisor = apiConfig.law.billReview + '/supervisor';
apiConfig.law.conclusion = apiConfig.law.billReview + '/conclusion';

apiConfig.calendar.event = apiConfig.law.path + '/calendarEvent';
apiConfig.calendar.parliamentSession = apiConfig.law.path + '/parliamentSession';

apiConfig.content.review = apiConfig.url + '/file/1/BillReviewContent/{id}';
apiConfig.content.package = apiConfig.url + '/file/1/BillPackageContent/{id}';

apiConfig.law.billTypes = apiConfig.url + '/law/lib/1/billReviewType';

apiConfig.colleague.getColleagues = apiConfig.colleague.path + '/';
apiConfig.colleague.getColleague = apiConfig.colleague.path + '/{id}';
apiConfig.colleague.education = apiConfig.colleague.getColleague + '/education';
apiConfig.colleague.appointment = apiConfig.colleague.getColleague + '/appointment';

apiConfig.colleague.mpQuestionsList = apiConfig.url + '/mpQuestion/1/';
apiConfig.colleague.mpQuestionsView = apiConfig.colleague.mpQuestionsList + '{id}';
apiConfig.colleague.mpQuestionsContent = apiConfig.colleague.mpQuestionsView + '/mpQuestionContent';
apiConfig.colleague.mpQuestionsContentLink = apiConfig.url + '/file/1/MpQuestionContent/{id}';

apiConfig.law.lawItems = apiConfig.law.path + '/lawItem/items/{id}';
apiConfig.law.lawItem = apiConfig.law.path + '/lawItem/{id}';
apiConfig.law.searchLawItem = apiConfig.law.path + '/lawItem';

/* /LAW CONTROLLER */

export default apiConfig;

export const exportBillList = (value: any) => ({
  id: value.id,
  key: value.id.toString(),
  title: truncate(value.billName, 10),
  date: value.billPackage.registrationDate.replace(/-/g, '/'),
  status: value.billReviewTypes ? value.billReviewTypes.pop() : null,
});

export const exportVotingSessionList = (value: any) => ({
  id: value.id,
  key: value.id.toString(),
  billId: value.billIdRd,
  reviewId: value.billReviewId,
  title: value.billRd,
  date: value.importConferenceDate,
  regNum: value.registrationNumberRd,
  reviewType: value.billReviewTypeRd,
  type: value.billTypeRd,
  backgroundColor: value.color || '#FFFFFF',
  color: !value.color || value.color === '#FFFFFF' || value.color === '#FFDBFF' ? '#5A5A5A' : '#FFFFFF',
  votingResultSet: value.votingResultSet,
});

export const exportVotingSessionListOfParliamentarian = (value: any) => ({
  id: value.voteSession.id,
  key: value.voteSession.id.toString(),
  billId: value.voteSession.billIdRd,
  reviewId: value.voteSession.billReviewId,
  title: value.voteSession.billRd,
  date: value.voteSession.importConferenceDate,
  regNum: value.voteSession.registrationNumberRd,
  reviewType: value.voteSession.billReviewTypeRd,
  type: value.voteSession.billTypeRd,
  backgroundColor: value.voteSession.color || '#FFFFFF',
  color: !value.voteSession.color || value.voteSession.color === '#FFFFFF' ? '#5A5A5A' : '#FFFFFF',
  votingResult: value.votingResult,
});

export const exportColleagueInfoImage = (value: any) => ({
  id: value.id,
  colleagueId: value.id,
  key: value.id.toString(),
  gender: value.gender.name,
  fullName: value.familyName + ' ' + value.firstName,
  name: value.familyName + ' ' + value.firstName,
  image: value.imageS || value.image,
  birthPlace: value.birthPlace,
  birthDate: value.dob,
  status: value.colleagueStatus,
  contactInfo: value.contactInfo,
  factionName: value.factionName,
  email: value.contactInfo ? (value.contactInfo.publicEmail1 || value.contactInfo.publicEmail1Exist) : null,
});

export const exportVotingDeputyList = (value: any) => ({
  id: value.id,
  key: value.id.toString(),
  colleagueId: value.colleagueId,
  name: value.colleagueRd,
  factionName: value.factionRd,
  image: value.image,
  votingResultSet: {yes: value.count1, withoutInteraction: value.count2, no: value.count3},
});

export const exportAgendaList = (value: any) => ({
  id: value.id,
  key: value.id.toString(),
  title: truncate(value.agendaName, 15),
  date: value.agendaDate.replace(/-/g, '/'),
});

export const exportAgendaReviewList = (value: any) => ({
  id: value.billReviewId,
  billId: value.billId,
  type: value.billReviewTypeName,
  key: value.billReviewId.toString(),
  title: value.billName,
  date: value.regNum,
});

export const exportBillViewData = (value: any) => ({
  id: value.id,
  key: value.id.toString(),
  title: value.billName,
  note: value.billNote,
  date: value.billPackage.registrationDate.replace(/-/g, '/'),
  registrationNumber: value.billPackage.registrationNumber.replace(/-/g, '/'),
  packageID: value.billPackage.id,
  author: value.billPackage.author,
  initiator: value.billPackage.initiator,
  reviewTypes: value.billReviewTypes.map((review: any) => ({id: review.id, key: review.id, name: review.name})),
});

export const getBill = async (id: any) => (
  fetch(
    apiConfig.law.billView.replace('{id}', id),
    apiConfig.options
  )
    .then(response => response.json())
    .then(data => exportBillViewData(data[0]))
    .catch(err => err)
);

export const exportBillReviewData = (value: any) => ({ id: value.id, key: value.id.toString(), name: value.reviewTypeName });

export const exportCalendarEventsMonthData = (value: any) => ({ from: value.fromDate, to: value.toDate });

export const exportCalendarEventsDayData = (value: any) => ({
  from: value.fromDate.split(' ')[1],
  to: value.toDate.split(' ')[1],
  name: value.whatEvent,
  location: value.whereEvent,
  id: value.objectId,
  key: value.objectId,
});

export const truncate = (str: any, limit: any) => str.split(' ').splice(0, limit).join(' ');

export const queryString = (obj: any) => {
  const keyValuePairs = [];
  // tslint:disable-next-line:forin
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.join('&');
};

export const getBillReviewTypes = async () => (
  fetch(
    apiConfig.law.billTypes,
    apiConfig.options
  )
    .then(response => response.json())
    .then(data => data.list)
    .catch(err => err)
);

export const fetchApi = async (str: string) => {
  try {
    const result = await fetch(
      str,
      apiConfig.options
    );
    return await result.json();
  } catch (e) {
    throw e;
  }
};
