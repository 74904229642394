import React from 'react';
import styled from 'styled-components/macro';
import { colors, font } from 'theme/colors';
import { ReactComponent as RightArrow } from 'assets/icons/right-arrow.svg';

const $ButtonRoot = styled.a<{ large?: boolean; }>`
  cursor: pointer;
  width: ${props => props.large ? 690 : 482 }px;
  height: ${props => props.large ? 249 : 174 }px;
  background: linear-gradient(102.16deg, #1D6EBB -6.94%, #092878 49.67%);
  border-radius: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.large ? 62 : 20 }px;
  color: ${colors.white};
  font-family: ${font.primary};
  box-shadow: -9px 0 0 0 ${colors.yellow} inset;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    width: ${props => props.large ? 207 : 144 }px;
    height: ${props => props.large ? 16 : 11 }px;
    background: linear-gradient(90deg, #3F8DD1 0%, #092878 82.13%);
    top: ${props => props.large ? 35 : 24 }px;
    right: ${props => props.large ? 80 : 55 }px;
    border-radius: 200px;
  }
  &::after {
    content: "";
    position: absolute;
    width: ${props => props.large ? 207 : 144 }px;
    height: ${props => props.large ? 16 : 11 }px;
    background: linear-gradient(90deg, #3F8DD1 0%, #092878 82.13%);
    bottom: ${props => props.large ? 35 : 24 }px;
    right: ${props => props.large ? 167 : 116 }px;
    border-radius: 200px;
  }
  & span.right-arrow {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    background: ${colors.yellow};
    width: ${props => props.large ? 59 : 41 }px;
    height: ${props => props.large ? 62 : 43 }px;
    border-top-left-radius: 69px;
    border-bottom-left-radius: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      margin-left: 10px;
    }
  }
`;

type PropTypes = {
  large?: boolean;
  [key: string]: any;
};

const ArrowButton: React.FC<PropTypes> = ({ children, large, ...props }) => {

  return (
    <$ButtonRoot large={large} {...props}>
      {children}
      <span className="right-arrow">
        <RightArrow width={38} height={38} />
      </span>
    </$ButtonRoot>
  );
};

export default ArrowButton;
