import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { CSSTransition } from 'react-transition-group';
import Background1 from 'assets/images/background-1.png';
import Logo from 'assets/images/logo.png';
import { colors, font } from 'theme/colors';
import ArrowButton from '../common/ArrowButton';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg';
import { useHistory, useParams } from 'react-router-dom';
import {
  COMMITTEE,
  EXCURSION,
  LANDING,
  LEGISLATIVE,
  MEMBERS,
  MOBILE_APP,
  PETITION,
} from '../../utils/routes';
import ModalContext from '../context/ModalContext';
import CalendarModal from './CalendarModal';

const $LandingComponent = styled.div`
  width: 100%;
  height: 100%;
  background: url('${Background1}') no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const $Logo = styled.img`
  width: 400px;
  height: auto;
  transition: top 0.3s;
  position: absolute;
  top: 21vh;
  &.top {
    top: 5vh;
  }
`;

const $ButtonList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 27vh;
  transition: margin 0.3s;
  &.top {
    margin-top: 25vh;
  }
`;

const $LargeButtonBorders = styled.div`
  position: relative;
  border-radius: 41px;
  border: 1px solid ${colors.lightBlue};
  border-left: 1px solid ${colors.yellow};
  border-right: 1px solid ${colors.yellow};
  box-shadow: -1px 0 0 0 ${colors.yellow} inset, -1px 0 0 0 ${colors.yellow};
  padding: 26px;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 41px;
    transform: translate(-50%, -50%);
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    border: 1px solid ${colors.lightBlue};
    border-left: 1px solid ${colors.yellow};
    border-right: 1px solid ${colors.yellow};
    box-shadow: -1px 0 0 0 ${colors.yellow} inset, -1px 0 0 0 ${colors.yellow};
    z-index: 0;
  }
`;

const $ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1000px;
  margin-bottom: 36px;
`;

const $CalendarButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 270px;
  height: 65px;
  background: linear-gradient(92.58deg, #1d6ebb -16.48%, #2a48e8 118.37%);
  font-size: 21px;
  font-family: ${font.primary};
  border-radius: 10px;
  color: #ffffff;
  position: absolute;
  top: 58px;
  left: 62px;
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0;
  transform: translateX(-300px);
  &.open {
    opacity: 1;
    transform: translateX(0);
  }
  & > span {
    display: flex;
    margin-left: 25px;
    margin-top: 4px;
  }
`;

const Landing = () => {
  const { launch: isLaunch = null } = useParams<{ launch?: string }>();
  const { openLiveModal } = useContext(ModalContext);
  const [launch, setLaunch] = useState(!!isLaunch);
  const [delay, setDelay] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isLaunch) {
      history.replace(LANDING + '#/page');
    }
  }, []);

  useEffect(() => {
    if (launch) {
      history.replace(LANDING + '#/page');
    } else {
      history.replace(LANDING);
    }
  }, [launch]);

  useEffect(() => {
    if (launch) {
      setTimeout(() => {
        setDelay(true);
      }, 200);
    } else {
      setDelay(false);
    }
  }, [launch]);

  const handleCalendar = () => {
    openLiveModal({
      content: <CalendarModal />,
      style: {
        maxWidth: 636,
        padding: 0,
      },
    });
  };

  const linkHandler = (url: string) => {
    history.replace(LANDING + 'launch');
    history.push(url);
  };

  return (
    <$LandingComponent>
      <$Logo
        src={Logo}
        onClick={() => setLaunch(false)}
        className={launch ? 'top' : ''}
      />
      <$ButtonList className={launch ? 'top' : ''}>
        <CSSTransition
          in={launch && delay}
          timeout={0}
          appear
          classNames="landing"
          unmountOnExit
        >
          <div className="landing">
            <$ButtonsRow>
              <ArrowButton onClick={() => linkHandler(MEMBERS)}>
                პარლამენტის წევრები
              </ArrowButton>
              <ArrowButton
                onClick={() => linkHandler(LEGISLATIVE)}
                style={{ paddingRight: 40 }}
              >
                საკანონმდებლო წინადადება
              </ArrowButton>
            </$ButtonsRow>
            <$ButtonsRow>
              <ArrowButton onClick={() => linkHandler(PETITION)}>
                პეტიცია
              </ArrowButton>
              <ArrowButton onClick={() => linkHandler(COMMITTEE)}>
                კომიტეტები
              </ArrowButton>
            </$ButtonsRow>
            <$ButtonsRow>
              <ArrowButton onClick={() => linkHandler(EXCURSION)}>
                ექსკურსია პარლამენტში
              </ArrowButton>
              <ArrowButton onClick={() => linkHandler(MOBILE_APP)}>
                მობილური აპლიკაცია
              </ArrowButton>
            </$ButtonsRow>
          </div>
        </CSSTransition>
        <CSSTransition
          in={!launch}
          timeout={250}
          classNames="landing"
          unmountOnExit
        >
          <div className="landing">
            <$LargeButtonBorders>
              <ArrowButton onClick={() => setLaunch(true)} large>
                ჩაერთე
              </ArrowButton>
            </$LargeButtonBorders>
          </div>
        </CSSTransition>
      </$ButtonList>
      <$CalendarButton
        className={launch ? 'open' : ''}
        onClick={handleCalendar}
      >
        <CalendarIcon />
        <span>კალენდარი</span>
      </$CalendarButton>
    </$LandingComponent>
  );
};

export default Landing;
