import React from 'react';
import styled from 'styled-components/macro';
import MainComponent from 'components/common/MainComponent';
import { font } from '../../theme/colors';
import Qr from 'assets/images/qr.png';
import Mobiles from 'assets/images/mobiles.png';
import Store from 'assets/images/store.png';

const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: calc(100vh - 130px);
  margin-left: auto;
  margin-right: auto;
`;

const $Images = styled.div`
  display: flex;
  flex-direction: row;
`;

const $Title = styled.div`
  font-size: 27px;
  line-height: 33px;
  text-align: center;
  font-weight: 400;
  font-family: ${font.secondary};
`;

const Mobile = () => {
  return (
    <MainComponent title="მობილური აპლიკაცია">
      <$Wrapper>
        <$Images>
          <img style={{ marginTop: 120, marginRight: 210 }} width="356" height="356" src={Qr} alt="qr" />
          <img src={Mobiles} width="746" height="644" alt="mobiles" />
        </$Images>
        <$Title>
          მობილური აპლიკაციის ჩამოტვირთვა შეგიძლიათ შემდეგი ოპერაციული
          სისტემებიდან:
        </$Title>
        <img src={Store} width="652" height="101" alt="store" />
      </$Wrapper>
    </MainComponent>
  );
};

export default Mobile;
