import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import Logo from 'assets/images/logo.png';
import Background2 from 'assets/images/background-2.png';
import { ReactComponent as BurgerMenu } from 'assets/icons/burger-icon.svg';
import { ReactComponent as Back } from 'assets/icons/page-back.svg';
import { font } from 'theme/colors';
import { LANDING } from 'utils/routes';
import { CSSTransition } from 'react-transition-group';
import Navigation from './Navigation';

const $PageBack = styled.div`
  position: absolute;
  top: 154px;
  left: 42px;
  width: 52px;
  height: 56px;
  display: flex;
  font-family: ${font.secondary};
  font-size: 8px;
  flex-direction: column;
  cursor: pointer;
`;

const $MainComponent = styled.div`
  width: 100%;
  background: url('${Background2}') no-repeat;
  background-position: bottom left;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
`;

const $Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 130px;
  padding-left: 20px;
  padding-right: 55px;
  background: radial-gradient(
    114.62% 114.62% at 50% -32.69%,
    #3f8dd1 0%,
    #041f69 100%
  );
  width: 100%;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  z-index: 1001;
`;

const $HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const $LogoImg = styled.img`
  width: 137px;
  height: auto;
  margin-right: 80px;
  cursor: pointer;
`;

const $Title = styled.span`
  color: #ffffff;
  font-size: 21px;
  font-family: ${font.primary};
`;

const $BurgerMenu = styled(BurgerMenu)`
  cursor: pointer;
`;

const $Content = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 130px;
  position: relative;
`;

type PropTypes = {
  title: string;
  style?: any;
  backStyle?: any;
};

const MainComponent: React.FC<PropTypes> = ({ children, title, style = null, backStyle = null }) => {
  const history = useHistory();
  const [navMenu, setNavMenu] = useState(false);

  return (
    <>
      <$MainComponent style={{ ...style }}>
        {/*<CSSTransition in timeout={0} classNames="header" unmountOnExit appear>*/}
        {/*  <$Header className="header">*/}
          <$Header>
            <$HeaderLeft>
              <$LogoImg onClick={() => history.push(LANDING + 'launch')} src={Logo} />
              <$Title>{title}</$Title>
            </$HeaderLeft>
            <$BurgerMenu onClick={() => setNavMenu(true)} />
          </$Header>
        {/*</CSSTransition>*/}
        <CSSTransition in timeout={0} classNames="page" unmountOnExit appear>
          <$Content className="page">
            <$PageBack onClick={() => history.goBack()} style={{ ...backStyle }}>
              <Back />
              <div style={{ whiteSpace: 'nowrap' }}>
                უკან გასვლა
              </div>
            </$PageBack>
            {children}
          </$Content>
        </CSSTransition>
      </$MainComponent>
      <Navigation open={navMenu} onClose={() => setNavMenu(false)} />
    </>
  );
};

export default MainComponent;
