import React from 'react';
import styled from 'styled-components/macro';
import MainComponent from 'components/common/MainComponent';
import { useParams } from 'react-router-dom';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { data } from './Committee';
import { font } from '../../theme/colors';

const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1120px;
  height: calc(100vh - 250px);
  margin-left: auto;
  margin-right: auto;
`;

const $Title = styled.div`
  font-family: ${font.secondary};
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  line-height: 26px;
  margin-bottom: 24px;
`;

const $Description = styled.div`
  font-family: ${font.secondary};
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 18px;
`;

const $ContactBox = styled.div`
  & > .title {
    font-family: ${font.secondary};
    font-weight: 400;
    font-size: 25px;
    text-align: center;
    color: rgba(29, 110, 187, 1);
    margin-bottom: 28px;
  }
  & > .contact {
    display: flex;
    flex-direction: row;
    font-family: ${font.secondary};
    font-weight: 400;
    font-size: 23px;
    & > div {
      display: flex;
      align-items: center;
      margin-right: 26px;
      & svg {
        margin-right: 10px;
        margin-top: -2px;
      }
    }
  }
`;

const CommitteeView = () => {
  const { id } = useParams<any>();
  const value = data[id - 1];

  return (
    <MainComponent title={value.name}>
      <$Wrapper>
        <div>
          <$Title>კომიტეტის საქმიანობის ძირითადი მიმართულებებია:</$Title>
          <$Description
            dangerouslySetInnerHTML={{ __html: value.description }}
          />
          <$Description>
            <br />
            <br />
            {value.members}
          </$Description>
        </div>
        <$ContactBox>
          <div className="title">საკონტაქტო ინფორმაცია</div>
          {value.contact.map((v, i) => (
            <div key={`contact-${i}`} className="contact">
              {v.email && (
                <div>
                  <Email />
                  {v.email}
                </div>
              )}
              {v.phone && (
                <div>
                  <Phone />
                  {v.phone}
                </div>
              )}
            </div>
          ))}
        </$ContactBox>
      </$Wrapper>
    </MainComponent>
  );
};

export default CommitteeView;
