import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { ReactComponent as LeftArrow } from 'assets/icons/calendar-left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/calendar-right.svg';
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from './CalendarConstants';
import { colors, font } from '../../theme/colors';
import {
  getDates,
  getFirstAndLastDates,
  getFormatedDate,
  reverseDate,
} from '../../utils/dates';
import apiConfig, {
  exportCalendarEventsDayData,
  exportCalendarEventsMonthData,
  fetchApi,
} from '../../utils/api';
import Loader from '../common/Loader';

const $CalendarRoot = styled.div`
  width: 100%;
  max-height: calc(100vh - 100px);
  position: relative;
  display: flex;
  flex-direction: column;
`;

const $EventsList = styled.div`
  width: 100%;
  padding: 15px;
  background-color: #f3f3f3;
  overflow: auto;
  max-height: fit-content;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background: rgba(196, 196, 196, 0.37);
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background: #1d6ebb;
    border-radius: 100px;
  }
`;

const $EventBox = styled.div`
  width: 100%;
  padding: 16px 19px 0 19px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 15px;
  & .date {
    font-size: 12px;
    font-weight: 400;
    font-family: ${font.secondary};
    color: #000000;
    margin-bottom: 10px;
  }
  & .title {
    font-size: 14px;
    font-weight: 400;
    font-family: ${font.secondary};
    color: #000000;
    padding-bottom: 10px;
  }
  & .location {
    font-size: 14px;
    font-weight: 400;
    font-family: ${font.secondary};
    color: #a6a6a6;
    padding-bottom: 11px;
  }
`;

const $CalendarBox = styled.div`
  & .DayPicker-wrapper {
    padding-bottom: 8px;
  }
  & .DayPicker-Day--highlighted {
    &::after {
      content: '';
      position: absolute;
      bottom: 10px;
      width: 11px;
      height: 11px;
      background-color: #e3cf86;
      border-radius: 11px;
      left: 50%;
      margin-left: -5px;
    }
  }
  & .DayPicker-Day--outside.DayPicker-Day--highlighted {
    &::after {
      display: none;
    }
  }
  & * {
    font-family: ${font.secondary};
  }
  & .DayPicker-Caption {
    color: #092878;
    font-size: 21px;
    & > div {
      font-size: 21px;
      font-weight: 400;
      text-align: center;
      margin-left: 48px;
      width: 220px;
    }
  }
  & .DayPicker-Weekday {
    color: #9d9d9d;
    font-size: 21px;
    padding: 24px 0;
    width: 86px;
    padding-bottom: 4px;
  }
  & .DayPicker-Day {
    font-size: 21px;
    color: #092878;
    width: 85px;
    padding: 0;
    padding-top: 22px;
    padding-bottom: 22px;
    position: relative;
    background: transparent !important;
  }
  & .DayPicker-Day--today {
    color: #007dff !important;
  }
  & .DayPicker-Day--selected {
    color: #ffffff !important;
    &::before {
      content: '';
      position: absolute;
      bottom: 4px;
      width: 58px;
      height: 58px;
      background-color: #e3cf86;
      border-radius: 58px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
    &::after {
      background-color: #ffffff;
    }
  }
  & .DayPicker-Months {
    margin-top: 20px;
  }
  & .DayPicker-Caption {
    margin-bottom: 0;
  }
`;

const $Loader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.65);
  top: 0;
  left: 0;
  z-index: 1;
`;

const $NavBox = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 8px;
  left: 32px;
  width: 300px;
  justify-content: space-between;
`;

const CalendarModal = () => {
  const [loader, setLoader] = useState(false);
  const [markedDates, setMarkedDates] = useState<any>({});
  const [list, setList] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState();
  const monthChange = async (month: any) => {
    const changedDate = new Date(month);
    const [firstDay, lastDay] = getFirstAndLastDates(
      changedDate.getFullYear(),
      changedDate.getMonth(),
    );
    try {
      const data = await _getCalendarMonthEvents(
        getFormatedDate(firstDay),
        getFormatedDate(lastDay),
      );
      const newMarkedDates = {
        ..._buildMarkedDates(data.map(exportCalendarEventsMonthData)),
      };
      setMarkedDates((prev: any) => ({
        ...prev,
        ...newMarkedDates,
      }));
    } catch (e) {}
  };

  useEffect(() => {
    monthChange(new Date());
  }, []);

  useEffect(() => {
    if (selectedDate) {
      setLoader(true);
      const reversedDate: any = getFormatedDate(selectedDate);
      _getCalendarMonthEvents(null, null, reversedDate).then((data) => {
        setLoader(false);
        setList([...data.map(exportCalendarEventsDayData).reverse()]);
      });
    }
  }, [selectedDate]);

  const highlighted = useMemo(
    () => Object.keys(markedDates).map((date: string) => new Date(date)),
    [markedDates],
  );

  const handleDayClick = (day: any) => {
    const dateString = reverseDate(getFormatedDate(day));
    if (markedDates[dateString]) {
      setSelectedDate(day);
    }
  };

  return (
    <$CalendarRoot>
      {loader && (
        <$Loader>
          <Loader type="Oval" color={colors.blue} />
        </$Loader>
      )}
      <$CalendarBox>
        <DayPicker
          navbarElement={<Navbar />}
          firstDayOfWeek={1}
          months={MONTHS}
          weekdaysLong={WEEKDAYS_LONG}
          weekdaysShort={WEEKDAYS_SHORT}
          modifiers={{ highlighted }}
          selectedDays={selectedDate}
          onDayClick={handleDayClick}
          onMonthChange={monthChange}
        />
      </$CalendarBox>
      {list.length > 0 && (
        <$EventsList>
          {list.map((event: any) => (
            <$EventBox key={event.id}>
              {event.from !== event.to ? (
                <div className="date">{`${event.from.slice(
                  0,
                  5,
                )} - ${event.to.slice(0, 5)}`}</div>
              ) : event.from ? (
                <div className="date">{`${event.from.slice(
                  0,
                  5,
                )}`}</div>
              ) : null}
              <div className="title">{event.name}</div>
              {event.location && (
                <div className="location">{event.location}</div>
              )}
            </$EventBox>
          ))}
        </$EventsList>
      )}
    </$CalendarRoot>
  );
};

function Navbar({ onPreviousClick, onNextClick, className }: any) {
  return (
    <$NavBox className={className}>
      <LeftArrow onClick={() => onPreviousClick()} />
      <RightArrow onClick={() => onNextClick()} />
    </$NavBox>
  );
}

const _buildMarkedDates = (data: any) => {
  const allDates: any = {};
  data.forEach((value: any) => {
    const tempArr = getDates(
      new Date(reverseDate(value.from)),
      new Date(reverseDate(value.to)),
    );
    tempArr.forEach((value2) => {
      const tempValue = reverseDate(value2);
      if (!allDates[tempValue]) {
        allDates[tempValue] = { marked: true };
      }
    });
  });
  return allDates;
};

const _getCalendarMonthEvents = async (
  to: any,
  from: any,
  currentDate = null,
) => {
  let url = `${apiConfig.calendar.event}?eventDateFrom1=${to}&eventDateFrom2=${from}`;
  if (currentDate) {
    url = `${apiConfig.calendar.event}?currentDate=${currentDate}`;
  }
  return fetchApi(url)
    .then((data) => data.list)
    .catch((err) => err);
};

export default CalendarModal;
