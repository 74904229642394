import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { Transition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { colors, font } from 'theme/colors';
import {
  COMMITTEE,
  EXCURSION,
  LEGISLATIVE,
  MEMBERS,
  MOBILE_APP,
  PETITION,
} from 'utils/routes';

const $Navigation = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1005;
`;

const $Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(3, 16, 51, 0.5);
  z-index: 101;
  opacity: 0;
  transition: opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const $Drawer = styled.div`
  width: 504px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 105;
  padding: 24px;
  background: radial-gradient(
    circle,
    rgba(29, 110, 187, 1) 0%,
    rgba(9, 40, 120, 1) 100%
  );
  transform: translateX(100%);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const $NavButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 77px;
  font-size: 24px;
  color: ${colors.white};
  background: ${colors.blue};
  margin-top: 31px;
  border-radius: 15px;
  font-family: ${font.secondary};
  padding-top: 4px;
  &.active {
    background: ${colors.lightBlue};
  }
`;

const $CloseIcon = styled(CloseIcon)`
  display: flex;
  margin-left: auto;
  margin-top: 16px;
  margin-right: 37px;
  margin-bottom: 44px;
  cursor: pointer;
`;

type PropTypes = {
  open: boolean;
  onClose: any;
};

export const backdropTransitionStyles: any = {
  entering: { opacity: 0 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};

const drawerTransitionStyles: any = {
  entering: { transform: 'translateX(100%)' },
  entered:  { transform: 'translateX(0px)' },
  exiting:  { transform: 'translateX(100%)' },
  exited:  { transform: 'translateX(100%)' },
};

const Navigation: React.FC<PropTypes> = ({ open, onClose }) => {
  const history = useHistory();
  const memberMatch = useRouteMatch(MEMBERS);
  const legislativeMatch = useRouteMatch(LEGISLATIVE);
  const committeeMatch = useRouteMatch(COMMITTEE);
  const mobileMatch = useRouteMatch(MOBILE_APP);
  const petitionMatch = useRouteMatch(PETITION);
  const excursionMatch = useRouteMatch(EXCURSION);

  const navHandler = useCallback(
    (event: React.SyntheticEvent<any>) => {
      history.push(event.currentTarget.dataset.to);
      onClose();
    },
    [history, onClose],
  );

  return (
    <Transition
      in={open}
      timeout={{ exit: 225 }}
      unmountOnExit
    >
      {state => (
        <$Navigation className="navigation">
          <$Backdrop onClick={onClose} style={{ ...backdropTransitionStyles[state] }} />
          <$Drawer style={{ ...drawerTransitionStyles[state] }} >
            <$CloseIcon onClick={onClose} />
            <$NavButton
              data-to={MEMBERS}
              onClick={navHandler}
              className={memberMatch ? 'active' : ''}
            >
              პარლამენტის წევრები
            </$NavButton>
            <$NavButton
                data-to={LEGISLATIVE}
                onClick={navHandler}
                className={legislativeMatch ? 'active' : ''}
            >
              საკანონმდებლო წინადადება
            </$NavButton>
            <$NavButton
                data-to={COMMITTEE}
                onClick={navHandler}
                className={committeeMatch ? 'active' : ''}
            >
              კომიტეტები
            </$NavButton>
            <$NavButton
                data-to={MOBILE_APP}
                onClick={navHandler}
                className={mobileMatch ? 'active' : ''}
            >
              მობილური აპლიკაცია
            </$NavButton>
            <$NavButton
                data-to={PETITION}
                onClick={navHandler}
                className={petitionMatch ? 'active' : ''}
            >
              პეტიცია
            </$NavButton>
            <$NavButton
                data-to={EXCURSION}
                onClick={navHandler}
                className={excursionMatch ? 'active' : ''}
            >
              ექსკურსია პარლამენტში
            </$NavButton>
          </$Drawer>
        </$Navigation>
      )}
    </Transition>
  );
};

export default Navigation;
